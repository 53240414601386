<template>
  <MiscellaneousRegion dialog-name="Regions" />
</template>
<script>
import MiscellaneousRegion from '@/views/app/miscellaneous/Region'

export default {
  name: 'RegionWrapper',
  components: {
    MiscellaneousRegion
  }
}
</script>
